<template>
  <div class="d-print-none">
    <div v-if="!expand" class="list-content ma-1">
      <DragDropFile
        :disabledrop="disabledrop"
        :item="source"
        class="complex"
        :editor="editormultiple"
        :onItem="true"
        :allowdrop="authorization.update"
        :authorization="authorization"
        @dropText="dropText"
      >
        <v-list
          dense
          class="pa-0 ma-0"
          :class="[{ dragicon: isInbox || authorization.update }]"
        >
          <v-list-item
            :id="source._id"
            :data-index="itemindex"
            :key="'itemTitle' + source._id"
            class="ma-0 navigate"
            :class="[
              highlighted ? 'selected' : '',
              properties.display.fields.displayFullDescription ||
              properties.display.fields.displayDescription
                ? 'blockui'
                : '',
            ]"
          >
            <v-list-item-avatar
              color="orange accent-1"
              :size="isMobile ? 50 : 30"
              :title="userInfo.display"
              v-show="properties.display.fields.assignee && source.assignee"
            >
              <AsyncAvatarImage
                :username="source.assignee"
                :collection="collection"
              />
            </v-list-item-avatar>
            <v-list-item-content
              class="pl-2 link"
              @click="highlightSelected(source)"
              @contextmenu.prevent="
                highlightSelected(source);
                openIneditMode($event);
              "
            >
              <v-list-item-title v-html="source.title" class="wrap-title" />
              <div
                class="item-body"
                v-show="properties.display.fields.displayDescription"
              >
                <span
                  :class="
                    properties.display.fields.displayFullDescription
                      ? 'no-line'
                      : 'max-lines'
                  "
                  :style="
                    properties.display.fields.displayFullDescription
                      ? ''
                      : utils.setMaxLines(3)
                  "
                >
                  <markdown-it-vue
                    class="md-body"
                    :content="source.body"
                    :collection="collection"
                  />
                </span>
              </div>
            </v-list-item-content>
            <v-list-item-action
              v-if="properties.display.fields.thumbnail"
              class="mx-3 pointer thumbnail"
              @click="openImage()"
            >
              <FeatureImage
                :item="source"
                :attachmentView="attachmentView"
                :thumbSize="{ width: 100, height: 0 }"
                :collection="collection"
                :height="
                  isMobile && properties.display.fields.displayFullDescription
                    ? '100px'
                    : '50px'
                "
                :key="'listFeature' + source._rev"
                @hasImage="getFeatureImage"
                :borderRadius="true"
                class="thumbnail"
              />
            </v-list-item-action>
            <v-list-item-action class="mx-1" @click="openDetail(source)">
              <div class="expand-box expand-icon">
                <v-icon class="small-icon primary--text"
                  >mdi-arrow-expand</v-icon
                >
              </div>
            </v-list-item-action>
            <v-list-item-action
              class="mx-1"
              @click="toggleCollapseComment = !toggleCollapseComment"
            >
              <div class="comment-box">
                <div v-if="comments">
                  <v-icon class="small-icon primary--text"
                    >mdi-comment-text-outline</v-icon
                  ><span class="item-font">{{ comments }}</span>
                </div>
                <div v-else class="comment-icon">
                  <v-icon class="small-icon primary--text"
                    >mdi-comment-plus-outline</v-icon
                  >
                </div>
              </div>
            </v-list-item-action>
            <v-list-item-action
              v-if="attachments"
              class="mx-1"
              @click="toggleCollapseAttachment = !toggleCollapseAttachment"
            >
              <div class="attachment-box">
                <v-icon class="small-icon primary--text">mdi-attachment</v-icon
                ><span class="item-font">{{ attachments }}</span>
              </div>
            </v-list-item-action>
            <v-list-item-action
              class="mx-1"
              v-if="
                (isInbox || authorization.update) &&
                properties.display.fields.checkbox
              "
            >
              <v-checkbox
                v-model="source.completed"
                @change="setCompletion()"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </DragDropFile>
      <div
        class="mt-3 mx-2 d-flex px-4"
        v-if="toggleCollapseAttachment && attachments"
      >
        <Attachment
          :parentView="view"
          :authorization="authorization"
          ref="attachment"
          :item="source"
          :collection="collection"
          :key="'itemEditorAttachment' + source._rev"
          :icon="false"
          :height="'100px'"
        />
      </div>
      <div class="mt-3 mx-2 d-flex px-4" v-if="toggleCollapseComment">
        <InlineComment
          :collection="collection"
          :nameref="'commentCollapse'"
          :item="source"
          :key="'collapse_comment' + source._rev"
          :authorization="authorization"
          :showCommentText="toggleCollapseComment"
        />
      </div>
    </div>
    <div v-else :id="'itemcollapse' + source._id" v-outside-click="outsideSave">
      <v-card flat class="ma-1 extend expandselected" v-if="item !== null">
        <DragDropFile
          ref="dragdrop"
          :authorization="authorization"
          :item="item"
          :visible="false"
          class="simple"
          :editor="editormultiple"
        >
          <div
            class="maincontainer mx-1 navigate"
            :id="item._id"
            :key="'itemTitle' + source._id"
          >
            <v-row no-gutters>
              <v-col cols="12">
                <div class="float-left" style="width: calc(100% - 50px)">
                  <div
                    class="mt-0 d-flex"
                    style="
                      margin-left: -1px !important;
                      margin-top: 3px !important;
                    "
                  >
                    <v-textarea
                      autofocusitem
                      tabindex="1"
                      auto-grow
                      solo
                      rows="1"
                      row-height="15"
                      flat
                      ref="itemTitle"
                      :readonly="!authorization.update"
                      v-model="item.title"
                      label="Title here..."
                      hide-details
                      background-color="transparent"
                      class="custom-input-title text-left"
                      @keydown.enter.prevent="keyDown"
                    />
                  </div>
                  <!-- Description area -->
                  <div
                    class="mt-0 d-flex"
                    style="margin-top: -2.5px !important; padding: 0 20px"
                  >
                    <text-area
                      :tabindex="2"
                      ref="textarea"
                      :enableMarkdown="editmode"
                      :authorization="authorization"
                      v-model="item.body"
                      :autoheight="false"
                      class="smallarea item-font"
                      :key="editmode"
                      @attachment="syncAttachment"
                      :collection="collection"
                      @submitSave="submitSave"
                    />
                  </div>
                </div>
                <div class="text-center float-right" style="width: 50px">
                  <div
                    class="d-block"
                    v-if="
                      (isInbox || authorization.update) &&
                      properties.display.fields.checkbox
                    "
                  >
                    <v-checkbox
                      v-model="source.completed"
                      @change="setCompletion()"
                      hide-details
                      class="pa-0"
                      style="margin: 0 0 0 10px"
                    />
                  </div>
                  <div class="d-block">
                    <v-btn
                      @click="openDetail(item)"
                      icon
                      small
                      tabindex="3"
                      class=""
                      ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                  </div>
                  <div class="d-block">
                    <div
                      class="comment-box"
                      @click="toggleExpandComment = !toggleExpandComment"
                    >
                      <div v-if="comments">
                        <v-icon class="small-icon primary--text"
                          >mdi-comment-text-outline</v-icon
                        ><span class="item-font">{{ comments }}</span>
                      </div>
                      <div v-else>
                        <v-icon class="small-icon primary--text"
                          >mdi-comment-plus-outline</v-icon
                        >
                      </div>
                    </div>
                  </div>
                  <div class="d-block">
                    <div class="attachment-box">
                      <div v-if="attachments">
                        <v-icon class="small-icon primary--text"
                          >mdi-attachment</v-icon
                        ><span class="item-font">{{ attachments }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-block">
                    <v-btn
                      small
                      class="uploadicon"
                      @click="UploadClick()"
                      icon
                      tabindex="4"
                      v-if="authorization.update"
                    >
                      <v-icon>mdi-cloud-upload</v-icon></v-btn
                    >
                  </div>
                  <div class="d-block">
                    <v-btn
                      small
                      icon
                      class=""
                      tabindex="5"
                      @click="removeItem(item)"
                      v-if="isInbox || authorization.update"
                    >
                      <v-icon class="remove-email-icon" color="red"
                        >mdi-minus-circle</v-icon
                      >
                    </v-btn>
                  </div>
                  <div @focus="autofocus" tabindex="6"></div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="mt-3 px-5">
                  <Attachment
                    :parentView="view"
                    :authorization="authorization"
                    ref="attachment"
                    :item="source"
                    :collection="collection"
                    :key="'itemEditorAttachment' + source._rev"
                    :icon="false"
                    :height="'100px'"
                  />
                </div>
                <div class="mt-3 px-5" v-if="toggleExpandComment">
                  <InlineComment
                    :lineThree="false"
                    :collection="collection"
                    :nameref="'commentExpand'"
                    :item="item"
                    :key="'expand_comment' + item._rev"
                    :authorization="authorization"
                    :showCommentText="toggleExpandComment"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </DragDropFile>
      </v-card>
    </div>
  </div>
</template>
<script>
import Utility from '@/components/common/utils.js'
import TextArea from '@/components/common/markdownit/text-area.vue'
import lodash from 'lodash'
import { OutsideClick } from '@/directives/OutsideClick.js'
import { View } from '@/collections'
import _attachment from '@/components/common/attachment.js'
export default {
  name: 'item',
  components: {
    TextArea,
    DragDropFile: () => import('@/components/fileagent/DragDropFile.vue'),
    FeatureImage: () => import('@/components/FeatureImage.vue'),
    AsyncAvatarImage: () => import('@/components/AsyncAvatarImage.vue'),
    InlineComment: () => import('@/components/InlineComment.vue'),
    Attachment: () => import('@/components/Attachment.vue')
  },
  data: function () {
    return {
      item: null,
      expand: false,
      utils: Utility,
      userInfo: {},
      editmode: false,
      commentView: null,
      attachmentView: null,
      toggleCollapseComment: false,
      toggleExpandComment: false,
      toggleCollapseAttachment: false,
      attachmentInfo: []
    }
  },
  directives: {
    OutsideClick
  },
  props: {
    column: { type: Object },
    itemindex: Number,
    editormultiple: { type: Object },
    collectionID: String,
    account: String,
    orderable: Boolean,
    view: Object,
    authorization: Object,
    isInbox: Boolean,
    properties: Object,
    collection: Object,
    perspective: String,
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    disabledrop: {
      type: Boolean,
      default: false
    }
  },
  beforeDestroy () {
    if (this.perspective !== 'views') {
      this.save()
    }
    if (this.commentView !== null) this.commentView.close()
    if (this.attachmentView !== null) this.attachmentView.close()
  },
  computed: {
    comments () {
      return this.commentView !== null ? this.commentView.items.length : 0
    },
    attachments () {
      return this.attachmentView !== null
        ? this.attachmentView.items.length
        : 0
    },
    highlighted () {
      var sel = localStorage.getItem('selected')
      if (sel !== null && sel === this.source._id) return true

      return false
    },
    isMobile () {
      return !!(
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs'
      )
    },
    changeSave () {
      if (
        !lodash.isEqual(this.source, this.item) &&
        this.authorization.update
      ) {
        return true
      }
      return false
    }
  },
  async created () {
    this.commentView = await new View(this.collection).open({
      of: { container: this.source._id, type: 'comment' }
    })
    this.attachmentView = await new View(this.collection).open({
      of: { attached_to: this.source._id }
    })
  },
  async mounted () {
    if (this.perspective !== 'views') {
      document.addEventListener('keydown', this.keyListener)
      document.addEventListener('paste', this.onPasteUrl)
    }
    this.userInfo = await this.utils.getUserDetails(
      this.collection,
      this.source.assignee
    )
  },
  destroyed () {
    this.destroyPasteListener()
    document.removeEventListener('paste', this.onPasteUrl)
    document.removeEventListener('keydown', this.keyListener)
  },
  methods: {
    keyDown (e) {
      if (this.editmode && this.expand) {
        this.quickSave()
      }
    },
    openIneditMode (e) {
      e.preventDefault()
      this.editmode = true
      this.showExpand(this.source)
      this.$emit('collapseAll', this.source._id)
    },
    quickSave () {
      this.save()
      this.highlightItemSelectedAfterEnter(this.source._id)
    },
    submitSave () {
      var sel = localStorage.getItem('selected')
      if (sel !== null && sel === this.source._id && this.item !== null) {
        this.quickSave()
      }
    },
    async keyListener (e) {
      var sel = localStorage.getItem('selected')
      var x = document.getElementsByClassName('v-dialog__content--active')
      if (x.length > 0) {
      } else if (
        e.keyCode === 39 &&
        sel !== null &&
        sel === this.source._id &&
        e.target.type !== 'textarea' &&
        e.target.type !== 'text'
      ) {
        // arroe key right
        this.openDetail(this.source)
      } else if (e.key === 'Enter' && sel !== null && sel === this.source._id) {
        if (typeof e.target.type === 'undefined') {
          e.preventDefault()
          if (this.editmode && this.expand) {
            // third enter hit
            // save item
            this.quickSave()
          } else if (!this.editmode && this.expand) {
            // second enter hit
            // enable edit mode and set autofocus
            this.editmode = true
          } else if (!this.editmode) {
            // first enter hit
            // expand item in non edit mode
            this.showExpand(this.source)
          }
        } else if (this.item !== null && e.target.type !== 'textarea') {
          e.preventDefault() // present browser event trigger textarea
          this.quickSave()
        }
      } else if (
        (e.keyCode === 8 || e.keyCode === 46) &&
        sel !== null &&
        sel === this.source._id &&
        typeof e.target.type === 'undefined'
      ) {
        // backspace or delete
        this.removeItem(this.source)
      } else if (
        e.key === 'Escape' &&
        sel !== null &&
        sel === this.source._id
      ) {
        if (this.changeSave && this.item) {
          const res = await this.$confirm(
            'There are some unsaved changes. Do you want to save',
            {
              title: `Save`,
              buttonSaveText: `Save`,
              buttonDiscardText: 'Discard',
              buttonCancelText: 'Cancel'
            }
          )
          if (res == 1) {
            this.expand = false
            this.save()
          } else if (res == 0) {
            this.expand = false
            Object.assign(this.$data, this.$options.data.apply(this))
            this.destroyPasteListener()
          } else {
            this.$refs.itemTitle.focus()
          }
        } else {
          this.expand = false
          Object.assign(this.$data, this.$options.data.apply(this))
          this.destroyPasteListener()
        }
      }
    },
    async setCompletion () {
      localStorage.removeItem('selected')
      await this.view.put(this.source)
    },
    async removeItem (item) {
      var nextitem
      const id = item._id
      const repo = this.view.dataSource.repo
      const deleted = await repo.removeItem(id, this)
      if (!deleted) return

      var els = document.querySelectorAll('.navigate')
      for (var i = 0; i < els.length; i++) {
        if (id == els[i].id) {
          nextitem = els[i + 1]
          // go for previous item
          if (typeof nextitem === 'undefined') {
            nextitem = els[i - 1]
          }
          // if no previous item then exit and remove item id from localstorage
          if (typeof nextitem === 'undefined') {
            localStorage.removeItem('selected')
            break
          }
          var type = nextitem.getAttribute('data-type')
          if (type === 'heading') {
            continue
          }
          console.log('next item', nextitem)
          break
        }
      }
      if (typeof nextitem !== 'undefined') this.highlightItem(nextitem.id)
      this.item = null
    },
    showExpand (item) {
      if (this.perspective === 'views') {
        return
      }
      this.expand = true
      this.item = Object.assign({}, this.item, item)
      if (this.perspective !== 'views') {
        document.addEventListener('paste', this.onPaste)
      }
    },
    destroyPasteListener () {
      document.removeEventListener('paste', this.onPaste)
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    },
    outsideSave () {
      this.save()
      var sel = localStorage.getItem('selected')
      this.highlightItemSelectedAfterEnter(sel)
    },
    async save (highlight = true) {
      if (this.item !== null) {
        var isClicked = this.editormultiple.isClicked
        var uploadClick = this.editormultiple.uploadDialog
        var attachmentClick =
          typeof this.$refs.textarea === 'undefined'
            ? false
            : this.$refs.textarea.attachemntDialog
        if (isClicked) {
          isClicked = !this.editormultiple.hasFile()
        }
        if (!uploadClick && !attachmentClick && !isClicked) {
          console.log('saved modified item')
          if (this.changeSave) {
            await this.view.put(this.item)
            if (this.attachmentInfo.length > 0) {
              await _attachment.add(
                this.item,
                this.attachmentInfo,
                this.collection
              )
              this.attachmentInfo = []
            }
          }
          this.expand = false
          this.item = null

          this.destroyPasteListener()
        }
      }
      this.editmode = false
    },
    highlightSelected (item) {
      if (this.perspective === 'views') {
        this.openDetail(item)
      } else {
        var doc = document.getElementById(item._id)
        if (doc.classList.contains('selected')) {
          setTimeout(() => {
            this.showExpand(item)
          }, 100)
        } else {
          this.highlightItem(item._id)
        }
      }
    },
    highlightItemSelected (_id) {
      localStorage.setItem('selected', _id)
      var element = document.getElementById(_id)
      var els = document.querySelectorAll('.navigate')
      for (var i = 0; i < els.length; i++) {
        els[i].classList.remove('selected')
      }
      element.classList.add('selected')
    },
    highlightItem (_id) {
      this.highlightItemSelected(_id)
      this.$emit('sync', _id)
    },
    highlightItemSelectedAfterEnter (_id) {
      setTimeout(() => {
        this.highlightItemSelected(_id)
      }, 100)
    },
    openDetail (item) {
      localStorage.setItem('selected', item._id)
      if (this.isInbox) {
        this.$router.push({
          name: 'list',
          params: {
            account: this.account,
            collectionID: this.collectionID,
            perspective: '_inbox',
            mode: item._id
          }
        })
      } else {
        this.$router.push({
          name: 'list',
          params: {
            account: this.account,
            collectionID: this.collectionID,
            perspective: item._id
          }
        })
      }
    },
    UploadClick () {
      this.$refs.dragdrop.click()
    },
    autofocus () {
      var doc = document.querySelector('[autofocusitem]')
      doc.focus()
    },
    onPaste (e) {
      console.log('paste called', e.clipboardData.files)
      var sel = localStorage.getItem('selected')
      if (
        this.authorization.update &&
        e.clipboardData.files.length > 0 &&
        sel === this.source._id &&
        e.target.type !== 'textarea' &&
        e.target.type !== 'text'
      ) {
        this.$refs.dragdrop.paste(e.clipboardData.files)
      }
    },
    async dropText (text) {
      if (this.utils.validateUrl(text)) {
        await _attachment.putUrl(this.attachmentView, this.source, text, this)
      }
    },
    onPasteUrl (event) {
      var sel = localStorage.getItem('selected')
      if (
        this.source._id == sel &&
        this.perspective !== 'views' &&
        this.authorization.update
      ) {
        var text = event.clipboardData.getData('Text')
        this.dropText(text)
      }
    },
    getFeatureImage (val) {
      this.firstImage = val.item
    },
    openImage () {
      this.$imagepreview('image', {
        view: this.view,
        item: this.firstImage,
        collecton: this.collection,
        authorization: this.authorization
      })
    }
  },
  watch: {
    editmode (newVal, oldVal) {
      if (newVal) {
        // only focus and highlight textbox title if  edit mode true
        this.$nextTick(() => {
          this.$refs.itemTitle.focus()
          this.$refs.itemTitle.$el.querySelector('textarea').select()
        })
      }
    }
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.link {
  cursor: pointer;
}
.item {
  cursor: move;
}
.headitem {
  cursor: move;
}
.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 50px !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px !important;
}

.theme--light .list-content {
  background-color: #fff !important;
  border-radius: 4px !important;
}

.theme--dark .list-content {
  background: #424242 !important;
  border-radius: 4px !important;
}

.theme--light.v-list {
  background: transparent !important;
}

.theme--dark.v-list {
  background: transparent !important;
}
.sortable-chosen .is-drag-valid.is-drag-over {
  background: none;
  display: none;
}
.selected {
  border-radius: 4px;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).selected {
  background-color: rgb(21, 101, 192, 0.4) !important;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).selected {
  background-color: rgb(239, 108, 0, 0.4) !important;
}
.theme--light .expandselected {
  outline: 2px solid rgb(21, 101, 192, 0.4) !important;
}
.theme--dark .expandselected {
  outline: 2px solid rgb(239, 108, 0, 0.4) !important;
}
.custom-input {
  margin: 0;
  min-height: 5px;
}
.extend {
  padding: 1px 0px;
}
.theme--dark .extend {
  background-color: #424242 !important;
}
.is-drag-valid.is-drag-over.simple .uploadicon {
  display: inline;
}
.v-list--dense .v-list-item .v-list-item__title {
  font-size: 0.9375rem !important;
}
.small-icon {
  font-size: 20px !important;
  margin-right: 3px;
}
.comment-box,
.attachment-box,
.expand-box {
  cursor: pointer;
}

.is-drag-valid.is-drag-over.complex {
  position: relative;
}

.comment-icon,
.expand-icon {
  display: none;
}
.list-content:hover .comment-icon,
.list-content:hover .expand-icon {
  display: block;
}
@media only screen and (max-width: 959px) {
  .blockui .v-list-item__title {
    text-align: center !important;
  }
  .blockui .item-body {
    text-align: left !important;
  }
  .blockui.v-list-item {
    display: block !important;
    text-align: center !important;
  }
  .blockui.v-list-item__content {
    display: block !important;
    text-align: justify !important;
    padding-left: 0 !important;
  }
  .blockui .thumbnail {
    display: block !important;
    padding-bottom: 16px;
  }
  .blockui .v-list-item__action {
    display: none;
  }
  .list-content:hover .blockui .v-list-item__action {
    display: inline-block;
  }
  .list-content:hover .blockui .thumbnail {
    padding-bottom: 0;
  }
}
.blockui .v-list-item__title,
.v-list-item__subtitle {
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: unset !important;
}
</style>
